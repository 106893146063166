import React from "react"

import styled from "styled-components"
import FreeCourseContent from "../../components/FreeCourseContent"
import { useSelector } from "react-redux"
import { useGetFreeCourseLmsQuery } from "../../components/features/api/authApi"

function Index(props) {
  const code = props.params["*"]
  const getIndex = props.params && code?.indexOf("/")
  const getLength = code?.length
  const slice = code?.slice(getIndex, getLength)
  //   const slugSliced = code?.slice(0, getIndex)
  const token = useSelector((state) => state.user.token)

  const { data, isLoading } = useGetFreeCourseLmsQuery(slice)

  console.log(data)
  return (
    <>
      {isLoading && (
        <Wrapper>
          <h1>Loading ...</h1>
        </Wrapper>
      )}
      {!isLoading && data && (
        <Container>
          {token && data ? (
            <FreeCourseContent
              data={data}
              keyId={slice}
              orderKey={slice}
              uri={props.uri}
              paid={false}
            />
          ) : (
            <h2>You need to login</h2>
          )}
        </Container>
      )}
    </>
  )
}

export default Index

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 900px;
`
const Container = styled.div`
  width: 100%;
  min-height: 900px;
  margin-top: 80px;
`
